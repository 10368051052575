$(function () {
    // Strict Mood Enable
    "use strict";
    //WOW JS
    new WOW().init();
    //Page Smooth Scroll
    smoothScroll.init({
        selector: '[data-scroll]',
        selectorHeader: null,
        speed: 800,
        easing: 'easeInQuint',
        offset: 80,
        callback: function (anchor, toggle) {
            ga('send', 'event', 'ViewPage', $(toggle).data('page'));
        }
	});

    $('.nav > li > a').click(function() {
        var navbar_toggle = $('.navbar-toggle');
        if (navbar_toggle.is(':visible')) {
            navbar_toggle.trigger('click');
        }
    });

    //Navbar Srink
    $(window).on('scroll', function () {
        if ($(document).scrollTop() > 50) {
            $('#header').addClass('shrink');
			} else {
            $('#header').removeClass('shrink');
		}
	});
    //ToolTip
    $('[data-toggle="tooltip"]').tooltip()
	
    // WOW carosel
    $("#carousel-team").owlCarousel({
        autoPlay: true,
        navigation: true,
        navigationText: ['<i class="fa fa-arrow-left" aria-hidden="true"></i>', '<i class="fa fa-arrow-right" aria-hidden="true"></i>'],
        items: 4,
        itemsDesktop: [1199, 4],
        itemsDesktopSmall: [979, 4]
	});
	
    $("#carousel-clients").owlCarousel({
        autoPlay: true,
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [979, 1]
	});
    $("#carousel-video").owlCarousel({
        autoPlay: false,
        nav: true,
        loop: true,
        rewindNav: true,
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [979, 1]
    });

    $("#carousel-header").owlCarousel({
        autoPlay: true,
        loop: true,
        nav: false,
        dots: false,
        pagination: false,
        items: 1,
        itemsDesktop: [1199, 1],
        itemsDesktopSmall: [979, 1],
        afterInit: function(){
            $("#carousel-header").find(".collapse").removeClass("collapse");
        }
    });
	
    $("#carousel-company").owlCarousel({
        autoPlay: true,
        items: 6,
        itemsDesktop: [1199, 6],
        itemsDesktopSmall: [979, 6]
	});

    $("#carousel-veikals").owlCarousel({
        autoPlay: true,
        items: 1
    });
	
	
    //MixitUp
    $('#mixup').mixItUp();
		
    //google maps
    $('#map').mapit();
    
    // Back to top
	$(window).on('scroll', function () {
		if ($(this).scrollTop() >= 600) { 
			$('#backtotop').fadeIn(500);  
			} else {
			$('#backtotop').fadeOut(500); 
		}
	});
	$('#backtotop').on('click', function() {   
		$('body,html').animate({
			scrollTop : 0  
		}, 500);
	});
    
});